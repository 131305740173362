import { escape } from "lodash"

export function htmlFromSolution(solutionObj) {
  return htmlTitle(solutionObj) + htmlFeatures(solutionObj)
}

export function htmlTitle(solutionObj) {
  const { description } = solutionObj

  return `<h2>${escape(description)}</h2>`
}

export function htmlFeatures(solutionObj) {
  const { features } = solutionObj

  const list = features?.list
    .map(({ text }) => `<li><div>${escape(text)}</div></li>`)
    .join("")

  return `
  <p>${escape(features.title)}</p>
  <ul>${list}</ul>
`
}
