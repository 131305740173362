import React from "react"
import tw, { styled } from "twin.macro"

import { Title, Body } from "components/industry-profiles-large"
import VideoModal from "components/sections/video-modal"

const Styles = styled.div`
  ${tw`mx-auto m:max-w-m l:max-w-l text-center`}

  @media (min-width: 1200px) {
    max-width: 900px;
  }

  ${Title} {
    ${tw`text-center`}
  }

  ${Body} {
    ${tw`mx-auto`}

    font-size: 16px;
    max-width: 800px;

    @media (min-width: 1200px) {
      font-size: 18px;
    }
  }

  .video {
    .gradient {
      ${tw`hidden`}
    }
  }
`

const ProductVideoSection = ({ className, contentBlock }) => {
  return (
    <Styles className={className}>
      {contentBlock.headerContent?.text && (
        <Title className="mb-4 product text-center title">
          {contentBlock.headerContent?.text}
        </Title>
      )}
      {contentBlock.mainContent?.html && (
        <Body
          dangerouslySetInnerHTML={{
            __html: `<div>${contentBlock.mainContent?.html}</div>`,
          }}
          className="mb-12 text-center body"
        />
      )}
      <VideoModal
        autoplayHash="play"
        className="mt-16 m:mt-24 l:mt-14 relative video"
        data={contentBlock.extraData}
      />
    </Styles>
  )
}

export default ProductVideoSection
