import React from "react"
import { graphql } from "gatsby"
// eslint-disable-next-line no-unused-vars
import { PageFragment } from "components/fragments"
import tw, { styled } from "twin.macro"

import Layout from "components/layout"
import SEO from "components/seo"
import SlantedSection from "components/sections/slant"
import VerticalLine from "components/vertical-line"
import ProductProfile from "components/product-profile"
import FeaturedVideo from "components/sections/featured-video"
import PartnerLogos from "components/partner-logos"
import ExploreOtherProducts from "components/sections/explore-other-products"
import { getSharePreviewUrls } from "utils/share-preview"
import { brandBlock } from "utils/brand"
import PageHeaderContentStyles from "components/page-header-content-styles"
import ProductVideoSection from "components/sections/product-video-section"
import ArticleShowcase from "components/sections/article-showcase"
import FeaturedContent from "components/sections/featured-content"
import FeaturedPressCarousel from "components/featured-press-carousel"
import IndustryBubbles from "../components/sections/industry-bubbles"

const PageBackground = styled.div`
  ${tw`mx-auto relative`}

  background: ${({ bgStack }) => bgStack.join(", ")};
  background-repeat: repeat-y;
  background-position: center;
  background-size: 100% auto;
  background-color: black;

  &.press-coverage {
    background-position: top;
  }

  @media (max-width: 640px) {
    background-size: 250% !important;
  }
`

const PressCoverage = styled.div`
  ${tw`mx-auto relative`}

  .hero {
    ${tw`mx-auto`}

    max-width: 800px;
    max-height: 200px;

    @media (min-width: 640px) {
      max-height: 450px;
    }

    @media (min-width: 1200px) {
      max-height: 580px;
    }
  }

  .header {
    ${tw`text-white text-center mx-auto`}

    font-size: 18px;
    line-height: 27px;
    max-width: 900px;

    h2 {
      ${tw`font-display font-bold mb-6`}

      font-size: 25px;
      line-height: 25px;

      @media (min-width: 640px) {
        font-size: 35px;
        line-height: 35px;
      }

      @media (min-width: 1024px) {
        font-size: 48px;
        line-height: 125%;
      }
    }
  }
`

function mapContentBlock(content, page) {
  content = brandBlock(content)

  return {
    id: content.identifier?.replace("product-group-item-", "") || content.id,
    heroImage: content.featuredImage,
    extraData: page.extraData,
    title: content.title,
    body: content.mainContent,
    solutionsProduct: content.solutionsProduct || null,
  }
}

const ProductGroup = ({ data }) => {
  const { gcms } = data
  const { page, demand360 } = gcms

  const {
    headerContent,
    sortedBlocks,
    extraData,
    featuredImage,
    insightsArticleSerieses,
  } = page

  const products = sortedBlocks.filter(({ identifier }) =>
    identifier.startsWith("product-group-item-")
  )
  const videoSection = sortedBlocks.find(
    ({ identifier }) => identifier === "video-section-block"
  )
  const pressSection = sortedBlocks.find(
    ({ identifier }) => identifier === "top-press-coverage-header"
  )
  const extraContentBlocks = sortedBlocks.filter(({ identifier }) =>
    identifier.endsWith("-extra-content")
  )
  const extraContentTopBlocks = sortedBlocks.filter(({ identifier }) =>
    identifier.endsWith("-extra-content-top")
  )

  const pressCoverageSeries = insightsArticleSerieses.find(
    ({ identifier }) => identifier === "recent-press-coverage"
  )

  const { featuredPressArticles, featuredLogosClients, featuredVideo } =
    demand360

  const mappedProducts = products.map((prod) => mapContentBlock(prod, page))

  const hasPressCoverage =
    featuredImage || pressSection?.headerContent || pressCoverageSeries

  const hasExtraTopCbs = extraContentTopBlocks.length > 0
  const hasExtraCbs = extraContentBlocks.length > 0

  const bgStack = [
    "linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 11.54%)",
    `url(${page.headerImg.url})`,
  ]

  const gradientStops = extraData?.colors?.bgGradient || []

  if (gradientStops.length === 2) {
    const colorsGradient = `linear-gradient(187.66deg, ${gradientStops[0]} 0.13%, ${gradientStops[1]} 16.56%)`
    bgStack.splice(1, 0, colorsGradient)
  }

  return (
    <Layout>
      {!!page && (
        <SEO
          title={page.title}
          description={page.description}
          path={page.path}
          sharePreviewUrls={getSharePreviewUrls(page)}
        />
      )}
      <PageBackground
        bgStack={bgStack}
        className={hasPressCoverage ? "press-coverage" : ""}
      >
        <div className="mx-auto max-w-s m:max-w-m l:max-w-l">
          <PageHeaderContentStyles
            className={`product-group ${
              hasPressCoverage ? "press-coverage" : ""
            }`}
            dangerouslySetInnerHTML={{
              __html: `<h1>${page.headerTitle}</h1>\n<div>${headerContent?.html}</div>`,
            }}
          />
          {hasPressCoverage && (
            <PressCoverage>
              {featuredImage && (
                <div className="hero">
                  <img src={featuredImage.url} alt="" />
                </div>
              )}
              {hasExtraTopCbs && (
                <div className="mb-24">
                  {extraContentTopBlocks.map((cb) => (
                    <FeaturedContent cb={cb} className="on-list" key={cb.id} />
                  ))}
                </div>
              )}
              {pressSection?.headerContent && (
                <div
                  className="header"
                  dangerouslySetInnerHTML={{
                    __html: pressSection.headerContent.html,
                  }}
                />
              )}
              {pressCoverageSeries && (
                <ArticleShowcase
                  articles={pressCoverageSeries.insightsArticles}
                  wide={true}
                  title={null}
                />
              )}
            </PressCoverage>
          )}
        </div>

        <div className="mx-auto max-w-s m:max-w-m l:max-w-l relative z-10">
          <div
            className={`relative l:mt-12 pb-16 ${hasExtraCbs ? "" : "m:pb-32"}`}
          >
            <VerticalLine className="products" />

            {mappedProducts.map((product) => (
              <ProductProfile
                page={product}
                key={product.id}
                productGroup={true}
              />
            ))}
          </div>
        </div>

        {videoSection && (
          <ProductVideoSection
            className={`${hasExtraCbs ? "mb-20" : "mb-40 m:mb-48"}`}
            contentBlock={videoSection}
          />
        )}

        {hasExtraCbs && (
          <div className="mb-24">
            {extraContentBlocks.map((cb) => (
              <FeaturedContent cb={cb} className="on-list" key={cb.id} />
            ))}
          </div>
        )}

        {featuredLogosClients && (
          <div className="mx-auto max-w-s m:max-w-m l:max-w-l mt-24 l:mt-20">
            <PartnerLogos data={featuredLogosClients} />
          </div>
        )}

        <div className="mx-auto max-w-s m:max-w-m l:max-w-l">
          <ExploreOtherProducts
            className="mt-5"
            page={page.path.split("/").pop()}
          />
        </div>
        <div className="py-32 m:pt-48 px-2 flex flex-col justify-around items-center content-center mx-auto max-w-s m:max-w-m l:max-w-l min-h-screen">
          <IndustryBubbles />
        </div>

        {featuredVideo && (
          <div className="mt-12">
            <FeaturedVideo video={featuredVideo} />
          </div>
        )}

        {featuredPressArticles?.length > 0 && (
          <SlantedSection className="pt-0 l:pt-14 z-10 pb-40">
            <FeaturedPressCarousel
              className="dark"
              articles={featuredPressArticles}
            />
          </SlantedSection>
        )}
      </PageBackground>
    </Layout>
  )
}

export const query = graphql`
  query ProductGroupPage($pagePath: String) {
    gcms {
      page(where: { path: $pagePath }) {
        ...PageData
        headerImg: headerImage {
          url(
            transformation: {
              image: { resize: { width: 1920, fit: clip } }
              document: { output: { format: webp } }
              validateOptions: true
            }
          )
        }
        sortedBlocks: contentBlocks {
          id
          title
          identifier
          headerContent {
            html
            text
          }
          mainContent {
            html
          }
          featuredImage {
            mimeType
            handle
            url
          }
          extraData
          solutionsProduct {
            description
            features
          }
          linkUrl
          label
          linkTargetBlank
          callToAction
          youtubeId
        }
        insightsArticleSerieses {
          identifier
          insightsArticles {
            ...InsightsData
            pressLogo {
              url(
                transformation: {
                  image: { resize: { width: 250, fit: clip } }
                  document: { output: { format: webp } }
                }
              )
            }
          }
        }
      }
      demand360: page(where: { path: "/products/demand360" }) {
        ...PageData
      }
    }
  }
`

export default ProductGroup
